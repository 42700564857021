import React, { useEffect, useState } from "react";
import { useMedia } from "use-media";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/UsecaesHeader";
import Container from "../../components/chat-channel/Container";
import AppsIntegrations from "../../components/common/usecases/appsIntegrations";
import ArrowRightICon from "../../components/common/Icons";
import AnchorLink from "react-anchor-link-smooth-scroll";
import LeftFormRightImage from "../../components/chat-channel/LeftFormRightImage";
import KeyResources from "../../components/common/KeyResources";
import Cards from "../../components/common/knowledge/Cards";
import {GoToPopup} from "../homepage"

import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { Slider } from "../../components/common/sliderHomePage";

export const blogImg = require("../../assets/img/integration/card_2.png");
export const whitepaperImg = require("../../assets/img/key-resources/bc_1_3.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_3.png");

const section_1 = require("../../assets/images/usecases/desktop/usecase_icon_2.png");
const section_2 = require("../../assets/images/usecases/desktop/usecase_icon_3.png");
const section_3 = require("../../assets/images/usecases/desktop/usecase_icon_1.png");

const section_1_mob = require("../../assets/images/usecases/mobile/usecase_icon_2_mob.png");
const section_2_mob = require("../../assets/images/usecases/mobile/usecase_icon_3_mob.png");
const section_3_mob = require("../../assets/images/usecases/mobile/usecase_icon_1_mob.png");

const slackteam = require("../../assets/images/usecases/user_provisioning/user_provisioning_last.png");
const automation_steps = require("../../assets/images/usecases/user_provisioning/user_provision_steps.png");
const mob_onboard_steps = require("../../assets/images/usecases/mobile/mob_user_steps.png");

const aboveFoldImage = require("../../assets/images/usecases/user_provisioning/user_provisioning_header.png");

const resoucesData = [
  {
    image: blogImg,
    header: "How to build integrations with Auth0 using Workativ Assistant",
    url:
      "https://blog.workativ.com/how-to-build-integrations-with-auth0-using-workativ-assistant/",
    link: "Read Blog",
    alt: "user provision automation"
  },
  {
    image: whitepaperImg,
    header: "Build JumpCloud Automations that scale with ChatOps ",
    url:
      "https://blog.workativ.com/how-to-enable-jumpcloud-chatops-with-workativ-assistant/",
    link: "Read Blog",
    alt: "user provision blog"
  },
  {
    image: videoImg,
    header: "How to Automate Unsuspend-user in Okta via Workativ chatbot",
    url: "https://youtu.be/aclU6eDiVKo",
    link: "Watch Video",
    alt: "user provision video"
  }
];
export const cardData = [
  {
    href: null,
    header: "Problem",
    content:
      "Employees lose time being productive due to delays in access to business applications, therefore increasing business operation costs.",
    backgroundClass: "card_one"
  },
  {
    href: null,
    header: "Solution",
    content:
      "An innovative, fully automated solution for user access and provisioning, through integration and automated workflows between IT, HR, and security apps.",
    backgroundClass: "card_two"
  },
  {
    href: null,
    header: "Impact",
    content:
      "Secure and faster access for users creates an improved employee experience with a significant reduction in time and cost for business.",
    backgroundClass: "card_three"
  }
];

const headerMenu = [
  {
    menu: "Overview",
    href: "#section_1",
    id: "section_1"
  },
  {
    menu: "Why automated user provisioning",
    href: "#section_2",
    id: "section_2"
  },
  {
    menu: "User Provisioning automation steps",
    href: "#section_3",
    id: "section_3"
  },
  {
    menu: "User Provisioning chatbot on Slack and Teams",
    href: "#section_4",
    id: "section_4"
  },
  {
    menu: " Other popular use cases",
    href: "#section_5",
    id: "section_3"
  }
];

const sectionImg = [
  {
    img: section_1,
    mobImage: section_1_mob,
    alt: "Automated user provisioning",
    header: "Automated user provisioning",
    paragraph:
      "Identity management procedures are always a part of security measures for a workforce, and it can become cumbersome without an automated system. With Workativ, you can create automated workflows by integrating with your existing IAM software to make the user provisioning quicker and more efficient while onboarding new hires. The AI + Automation platform from Workativ gives your teams a next-gen conversational AI solution to complete the user provisioning tasks in a snap."
  },
  {
    img: section_2,
    mobImage: section_2_mob,
    alt: "User deprovisioning",
    header: "User deprovisioning",
    paragraph:
      "Streamline user deprovisioning with pre-built automations in Workativ to ensure that your business is protected once employees leave. Using Workativ’s automation + AI platform, you can lighten the load on IT and HR teams by removing all access to employees. It takes no more than a few minutes for the entire deprovisioning process to get completed."
  },
  {
    img: section_3,
    mobImage: section_3_mob,
    alt: "Self-service user provisioning",
    header: "Self-service user provisioning",
    paragraph:
      "Integrate with your existing identity management system to create a self-service solution for user provisioning.Enable AI self-service via an intelligent chatbot through Workativ to reduce the time for IT teams to provision and deprovision users. Providing access to systems or applications to employees through a quicker, conversational self-service ensures user satisfaction and reduced time on repetitive requests."
  }
];

export default function HomePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState("Overview");

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("Overview");

  const listenScrollEvent = e => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="User Provisioning Automation - IT process automation software - Workativ "
        description="Automate User provisioning and User deprovisioning and enable AI self-service. Deploy user provisioning automation chatbot on Slack and Microsoft Teams now."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={aboveFoldImage}
        ogTitle="User Provisioning Automation - IT process automation software - Workativ "
        ogDescription="Automate User provisioning and User deprovisioning and enable AI self-service. Deploy user provisioning automation chatbot on Slack and Microsoft Teams now."
      />
      <Container>
      <Layout logoFor="ASSISTANT">
          <section className={header}>
            {/* <div className="container-fluid bg_apps_usecaes">
              <div className="row">
                <div className="container">
                  <div className="sticky_menu">
                    <span>User Provisioning:</span>
                    <ul>
                      {headerMenu.map(menu => (
                        <li>
                          <AnchorLink
                            offset={() => 160}
                            href={menu.href}
                            className={
                              isActive === menu.menu
                                ? "url_manipulation active"
                                : "url_manipulation "
                            }
                            onClick={() => {
                              setIsActive(menu.menu);
                            }}
                          >
                            {" "}
                            {menu.menu}
                          </AnchorLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}

            <RegisterSection
              backgroundColor={"bg_apps_usecaes "}
              rightImage={aboveFoldImage}
              altImage={"User Provisioning Automation"}
              videoToken={"__NO_VIDEO_AVAILABLE__"}
              headerMenu={headerMenu}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  User Provisioning Automation
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Reduce costs, increase business efficiency and improve
                  employee experience with user provisioning automation.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>
            {/* {isSmall ? null : <OnScrollPopup />} */}

            <div className="mob_sticky_wrapper">
              {/* <div className="dropdown wv-dropdown">
                <h4>User Provisioning:</h4>
                <button
                  className={`link ${isOpen ? "active" : ""}`}
                  onClick={() => setOpen(!isOpen)}
                >
                  {dropdown}
                </button>
                {headerMenu.map(menu => (
                  <div
                    onClick={() => setOpen(!isOpen)}
                    id="myDropdown"
                    className={`accordion-item submenu dropdown-content ${
                      !isOpen ? "collapsed" : ""
                    }`}
                  >
                    {" "}
                    <li className={menu.id}>
                      <AnchorLink
                        offset={460}
                        className="url_manipulation"
                        href={menu.href}
                        onClick={() => setDropdown(menu.menu)}
                      >
                        {menu.menu}
                      </AnchorLink>
                    </li>
                  </div>
                ))}
              </div> */}
              <AppsIntegrations
                header={"User Provisioning made easy with your favorite apps"}
                cards={appsName}
                id={"section_1"}
              />
              <section className="boarding_wrapper">
                <div className="container">
                  <div className="row">
                    {sectionImg.map((card, index) => {
                      if (index % 2 == 0) {
                        return (
                          <div className="boarding_wrapper_container">
                            <div className="row">
                              <div className="col-md-12 col-lg-3 col-12">
                                <picture>
                                  <source
                                    media="(max-width:768px)"
                                    srcSet={card.mobImage}
                                  />
                                  <img loading = "lazy" src={card.img} alt={card.alt}></img>
                                </picture>
                              </div>
                              <div className="col-md-12 col-lg-9 col-12 pad_left_30">
                                <h4>{card.header}</h4>
                                <p>{card.paragraph} </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div className="boarding_wrapper_container">
                          <div className="row">
                            <div className="col-md-12 col-lg-9 col-12 pad_right_30">
                              <h4>{card.header}</h4>
                              <p>{card.paragraph} </p>
                            </div>
                            <div className="col-md-12 col-lg-3 col-12">
                              <picture>
                                <source
                                  media="(max-width:768px)"
                                  srcSet={card.mobImage}
                                />
                                <img loading = "lazy" src={card.img} alt={card.alt}></img>
                              </picture>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <Cards
                id={"section_2"}
                cards={cardData}
                header={"Why automated user provisioning"}
              />
              <section className="automation_steps" id={"section_3"}>
                <div className="container">
                  <h2>How User Provisioning automation works</h2>

                  <picture>
                    <source
                      media="(max-width:768px)"
                      srcSet={mob_onboard_steps}
                    />
                    <img loading = "lazy"
                      src={automation_steps}
                      alt="How User Provisioning automation works"
                    />
                  </picture>
                </div>
              </section>
              <section className="slack_teams_wrapper" id={"section_4"}>
                <LeftFormRightImage
                  image={slackteam}
                  altImage={"User Provisioning Chatbot"}
                >
                  <LeftFormRightImage.Header>
                    <h3>User Provisioning on Slack and Microsoft Teams</h3>
                  </LeftFormRightImage.Header>
                  <LeftFormRightImage.SubHeader>
                    <p>
                      IT can move away from the complex process of user
                      provisioning, replaced with self-service on popular chat
                      channels — Slack and Microsoft Teams. Deploy a next-gen
                      AI-powered chatbot for IT to authorize access to apps for
                      employees, thereby improving FCR. Our chatbot works on
                      Slack and Teams to simplify handling repetitive tasks,
                      improving the efficiency of IT and HR support for
                      employees.
                    </p>
                  </LeftFormRightImage.SubHeader>
                  <LeftFormRightImage.Link>
                    <div className="card_link_landing">
                      <SlackAndTeams />
                    </div>
                  </LeftFormRightImage.Link>
                </LeftFormRightImage>
              </section>
              <Slider
                title={"Other popular use cases"}
                sliderData={sliderData}
                id={"section_5"}
              />
            </div>

            <div className="popular_workflows_">
            <KeyResources cards={resoucesData} />
            </div>
          </section>
          {/* <GoToPopup /> */}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

export const leave = require("../../assets/images/usecases/common/leave_management.png");
export const offboarding = require("../../assets/images/usecases/common/offboarding.png");
export const onboardinng = require("../../assets/images/usecases/common/onboardinng.png");
export const reset = require("../../assets/images/usecases/common/reset.png");
export const ticketing = require("../../assets/images/usecases/common/ticketing.png");
export const unlock = require("../../assets/images/usecases/common/unlock.png");
export const payroll = require("../../assets/images/usecases/common/payroll.png");

const sliderData = [
  {
    header: "Employee Offboarding",
    alt: "Employee Offboarding",
    image: offboarding,
    content:
      "Automate employee offboarding and remove the risk of ex-employees’ access to your systems and network ",
    link: "Learn more",
    url: "/use-cases/employee-offboarding-automation"
  },
  {
    header: "Unlock Account",
    alt: "Unlock Account Automation",
    image: unlock,
    content:
      "Strengthen enduser security and minimize calls to the helpdesk with ‘unlock account’ automation.",
    link: "Learn more",
    url: "/use-cases/unlock-account-automation"
  },
  {
    header: "Password Reset",
    alt: "Password Reset Automation",
    image: reset,
    content:
      "Expedite issue resolution for employees and reduce service desk call volume with ‘password reset’ automation.",
    link: "Learn more",
    url: "/use-cases/reset-password-automation"
  },

  {
    header: "Employee Onboarding",
    alt: "Employee Onboarding Automation",
    image: onboardinng,
    content:
      "Simplify the onboarding tasks for modern HR teams and deliver a positive onboarding experience.",
    link: "Learn more",
    url: "/use-cases/employee-onboarding-automation"
  },
  {
    header: "AI Ticketing",
    alt: "AI Ticketing Automation",
    image: ticketing,
    content:
      "Reduce contact with the helpdesk for repetitive issues and transform the employee experience with AI.",
    link: "Learn more",
    url: "/use-cases/ai-ticketing"
  },
  {
    header: "Payroll Enquiry",
    alt: "Payroll Enquiry Automation",
    image: payroll,
    content:
      "Streamline automation for delivering payroll information to employees with zero waiting time.",
    link: "Learn more",
    url: "/use-cases/payroll-enquiry-automation"
  },
  {
    header: "Leave management",
    alt: "Leave management automation",
    image: leave,
    content:
      "Simplify PTO inquiries and requests for employees with an automated, intelligent and user-friendly chatbot.",
    link: "Learn more",
    url: "/use-cases/leave-management-automation"
  }
];

const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const ms_office = require("../../assets/images/icons-web/ms_office.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const fd = require("../../assets/images/icons-web/fd_1.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const people_hr = require("../../assets/images/icons-web/people-hr.svg");
const purelyhr = require("../../assets/images/icons-web/purelyhr.svg");
const zohodesk = require("../../assets/images/icons-web/zohodesk.svg");

const appsName = [
  {
    image: bamboo,
    alt: "bamboo"
  },
  {
    image: ms_office,
    alt: "ms_office"
  },
  {
    image: freshservice,
    alt: "freshservice"
  },
  {
    image: zoho_people,
    alt: "Zoho People"
  },
  {
    image: fd,
    alt: "Freshdesk"
  },
  {
    image: people_hr,
    alt: "people_hr"
  },
  {
    image: purelyhr,
    alt: "people_hr"
  },
  {
    image: zohodesk,
    alt: "zohodesk"
  }
];

export const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
